<template>
  <div>
    <div class="contents">
      <div class="key-visual">
        <div class="paging">
          <div class="num">{{ detailData.id }}</div>
          <router-link :to="`/magazine/${nextid}`" v-if="nextid"
            ><span class="next">{{ nextid }}</span>
          </router-link>
        </div>
        <div class="img">
          <img :src="imgUrl(detailData.moDetailKeyPicturePhysicalName)" alt="" />
        </div>
      </div>
      <div class="page-tit">
        <div class="info-area">
          <span class="date">{{ detailData.reportingDate | dateFormat }}</span>
          <ul class="share">
            <li>
              <!-- <ShareNetwork
                network="facebook"
                :url="facebookUrl"
                title="이모션글로벌 | MAKE GREATNESS emotion "
                description="UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다."
                quote="UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다."
              >
                <img :src="require('@/assets/images/share-facebook.gif')" alt="페이스북 공유" />
              </ShareNetwork>-->
              <button type="button" @click="facebookShare">
                <img :src="require('@/assets/images/share-facebook.gif')" alt="페이스북 공유" />
              </button>
            </li>
            <li>
              <button type="button" @click="copyUrl">
                <img :src="require('@/assets/images/share-url.gif')" alt="url 공유" />
              </button>
            </li>
          </ul>
        </div>
        <h2 class="tit">
          {{ detailData.magazineTitle }}
        </h2>
      </div>
      <div class="page-cont">
        <div
          ref="getImg"
          class="page-cont-inner"
          v-html="handleNewLine(detailData.magazineContents)"
        ></div>
        <div class="writer">
          <div class="picture">
            <img :src="imgUrl(detailData.moAuthorImagePhysicalName)" alt="" />
          </div>
          <div class="info">
            <div class="personal">
              <span>{{ detailData.authorName }}</span>
              <span>{{ detailData.authorJobRank }}</span>
            </div>
            <div class="department">
              <span>{{ detailData.authorDepartment }}</span>
              <span>e&middot;motion</span>
            </div>
          </div>
        </div>
      </div>
      <div class="paging">
        <div class="num">{{ detailData.id }}</div>
        <router-link :to="`/magazine/${nextid}`" v-if="nextid"
          ><span class="next">{{ nextid }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import {apiMagazineDetail} from "@/api";
  import {commonMethods} from "@/utils/common-methods";

  export default {
    name: "item",
    /*metaInfo() {
      return {
        title: this.detailData?.magazineTitle || "Magazine",
        meta: [
          {
            property: "og:title",
            content: "EMOTION",
            //template: chunk => `${chunk} | EMOTION`,
            vmid: "og:title"
          },
          {
            property: "og:description",
            content: this.detailData?.magazineTitle,
            //template: chunk => `${chunk} | EMOTION`,
            vmid: "og:description"
          },
          {
            property: "og:image",
            content: this.imgUrl(this.detailData.moKeyPicturePhysicalName),
            //template: chunk => `${chunk} | EMOTION`,
            vmid: "og:image"
          }
        ]
      };
    },*/
    data() {
      return {
        scene: null,
        id: this.$route.params.id,
        nextid: null,
        detailData: [],
        imgSrc: process.env.VUE_APP_API_URL,
        originalSrc: document.location.origin,
        facebookUrl: window.location.href
      };
    },
    watch: {
      "$route.params.id"() {
        this.fetchData();
      }
    },
    mixins: [commonMethods],
    methods: {
      facebookShare() {
        window.open(
          `http://m.facebook.com/share.php?u=https://www.emotion.co.kr${window.location.pathname}${window.location.search}`
        );
      },
      copyUrl() {
        const t = document.createElement("textarea");
        document.body.appendChild(t);
        t.value = window.location.href;
        t.select();
        document.execCommand("copy");
        document.body.removeChild(t);
        alert("URL이 클립보드에 복사되었습니다.");
      },
      async fetchData() {
        try {
          const {
            data: {data: response}
          } = await apiMagazineDetail(this.$route.params.id);
          this.detailData = response;
          this.$nextTick(() => {
            this.srcChange();
          });
          this.nextid = this.detailData.abridgement.preAbridgement?.id;
        } catch (error) {
          console.error(error);
        }
      },
      srcChange() {
        this.$refs.getImg.querySelectorAll("img").forEach(el => {
          el.src = el.src.replace(this.originalSrc, this.imgSrc);
        });
      }
    },
    created() {
      this.fetchData();
    },
    updated() {
      window.scrollTo(0, 0);
    }
  };
</script>
<style lang="scss" scoped>
  .contents {
    padding: 0 40px;
  }
  .paging {
    display: flex;
    justify-content: space-between;
    .num {
      @include montserrat;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: -0.05em;
      color: #000;
    }
    .next {
      @include montserrat;
      display: block;
      position: relative;
      padding: 0 20px 0 0;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: -0.05em;
      color: #b7b7b7;
      &::before {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -2px;
        width: 6px;
        height: 1px;
        background: #b7b7b7;
        transform-origin: right bottom;
        transform: rotate(45deg);
      }
      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        right: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #b7b7b7;
      }
    }
  }
  .key-visual {
    padding: 120px 0 0;
    .img {
      margin: 27px -40px 0;
      height: auto;
    }
  }
  .page-tit {
    margin: 20px 0 0;
    word-break: keep-all;
    .info-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        @include montserrat;
        font-weight: 300;
        font-size: 11px;
        color: #b7b7b7;
      }
      .share {
        display: flex;
        li {
          & + li {
            margin: 0 0 0 7px;
          }
          button {
            display: block;
            border: none;
            padding: 0;
            background: none;
            font-size: 0;
          }
        }
      }
    }
    .tit {
      margin: 23px 0 0;
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      letter-spacing: -0.03em;
      color: #000;
    }
  }
  .page-cont {
    position: relative;
    margin: 15px 0 0;
    padding: 25px 0 55px;
    word-break: keep-all;
    & ~ .paging {
      padding: 0 0 27px;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ee2c3c;
    }
    &-inner {
      word-break: keep-all;
      line-height: 1.6;
      ::v-deep .group {
        margin: 30px 0 0;
        &:first-child {
          margin: 0;
        }
      }
      ::v-deep .img {
        margin: 35px 0 0;
      }
      ::v-deep table {
        width: 100% !important;
      }
      ::v-deep strong {
        font-weight: bold;
      }
      ::v-deep em {
        font-style: italic;
      }
      ::v-deep .img-sub {
        margin: 7px 0 0;
      }
      ::v-deep h2,
      ::v-deep h3,
      ::v-deep h4,
      ::v-deep h5,
      ::v-deep h6 {
        //margin: 30px 0 0;
        margin-top: 10px;
        font-weight: 700 !important;
        line-height: 1.5;
        letter-spacing: -0.03em;
        color: #000000;
        &:first-child {
          margin: 0;
        }
        & + p {
          margin: 9px 0 0;
        }
      }
      ::v-deep h2 {
        font-size: 20px;
      }
      ::v-deep h3 {
        font-size: 18px;
      }
      ::v-deep h4 {
        font-size: 16px;
      }
      ::v-deep h5 {
        font-size: 14px;
      }
      ::v-deep h6 {
        font-size: 14px;
      }
      ::v-deep ol {
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
      }
      ::v-deep ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
      }
      ::v-deep p {
        overflow: hidden;
      }
      ::v-deep p,
      ::v-deep li {
        font-size: 14px;
        letter-spacing: -0.03em;
        color: #333;
      }
      ::v-deep img {
        max-width: 100% !important;
        height: auto !important;
      }
      /*::v-deep span {
        display: block;
        font-size: 12px;
        line-height: 19px;
        color: #888;
      }*/
      ::v-deep video,
      ::v-deep iframe {
        max-width: 100%;
        height: auto;
      }
    }
    .writer {
      margin: 55px 0 0;
      text-align: center;
      .picture {
        overflow: hidden;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        border-radius: 50%;
      }
      .info {
        display: inline-block;
        margin: 17px 0 0;
        .personal {
          display: flex;
          justify-content: space-between;
          span {
            &:first-child {
              letter-spacing: -0.05em;
            }
            & + span {
              margin: 0 0 0 20px;
            }
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #000;
          }
        }
        .department {
          margin: 12px 0 0;
          text-align: left;
          span {
            @include montserrat;
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: #888;
          }
        }
      }
    }
  }
</style>
